<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <div class="col-10">
        <textarea
            v-model="orders"
            class="form-control"
            rows="15"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Process Canceled</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "OrderCancel",
  data: function () {
    return {
      orders: "",
      breadcrumbs: [{ name: "Order", link: "/order" }, { name: "Order Canceled" }],
      submitted: false,
      alertClass: "alert-danger",
      alertContent: ""
    };
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      axios.post("/order/cancel", { data: this.orders} )
        .then((response) => {
          this.submitted = false;
          this.alertContent = response.data;
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
  },
};
</script>
